import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { Box, Button, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

const Login = ({ onLogin }) => {
  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      onLogin(result.user);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f6f8",
      }}
    >
      <Typography variant="h3" sx={{ marginBottom: 2, fontWeight: "bold", color: "#1976d2" }}>
        Mileage Marketing
      </Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: 4, color: "#757575" }}>
        Count your mile points
      </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<GoogleIcon />}
        onClick={handleLogin}
        sx={{
          padding: "10px 20px",
          fontSize: "16px",
        }}
      >
        Login with Google
      </Button>
    </Box>
  );
};

export default Login;
