import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Verified Icon
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

const Profile = ({ user }) => {
  const [profile, setProfile] = useState({
    displayName: user.displayName || "",
    email: user.email || "",
    phone: "",
    photoURL: user.photoURL || "",
    isVerified: false,
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  // Fetch user profile from Firestore
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const profileData = docSnap.data();
          setProfile({
            displayName: profileData.displayName || user.displayName,
            email: profileData.email || user.email,
            phone: profileData.phone || "",
            photoURL: profileData.photoURL || user.photoURL,
            isVerified: profileData.verified || false, // Map "verified" field from Firestore
          });
        } else {
          // Initialize profile in Firestore if it doesn't exist
          await setDoc(docRef, {
            displayName: user.displayName,
            email: user.email,
            phone: "",
            photoURL: user.photoURL || "",
            verified: false, // Initialize verified as false
          });
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [user]);

  // Handle Logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.reload(); // Redirect to login page
    } catch (error) {
      console.error("Error logging out:", error);
      alert("Failed to log out. Please try again.");
    }
  };

  // Handle Profile Picture Upload
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      alert("Please select a file to upload.");
      return;
    }

    const fileName = `${Date.now()}_${file.name}`;
    const storage = getStorage();
    const storageRef = ref(storage, `profilePictures/${user.uid}/${fileName}`);

    try {
      setIsUploading(true);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      setProfile((prevProfile) => ({
        ...prevProfile,
        photoURL: downloadURL,
      }));

      // Update Firestore with new photo URL
      await setDoc(
        doc(db, "users", user.uid),
        { photoURL: downloadURL },
        { merge: true }
      );

      alert("Profile picture uploaded successfully!");
      setIsUploading(false);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
      alert("Failed to upload profile picture. Please try again.");
      setIsUploading(false);
    }
  };

  // Handle Save Changes
  const handleSaveChanges = async () => {
    try {
      // Update Firestore with profile changes
      await setDoc(
        doc(db, "users", user.uid),
        {
          displayName: profile.displayName,
          phone: profile.phone,
        },
        { merge: true }
      );

      alert("Profile updated successfully!");
      setIsEditing(false);
    } catch (error) {
      console.error("Error saving profile changes:", error);
      alert("Failed to save profile changes. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: "white",
        borderRadius: 2,
        boxShadow: 2,
        textAlign: "center",
      }}
    >
      <Avatar
        src={profile.photoURL}
        alt={profile.displayName}
        sx={{ width: 100, height: 100, margin: "0 auto 16px" }}
      />
      <Typography variant="h6" gutterBottom>
        {profile.displayName}
        {profile.isVerified && (
          <CheckCircleIcon
            sx={{
              fontSize: 20,
              color: "green",
              marginLeft: 1,
              verticalAlign: "middle",
            }}
          />
        )}
      </Typography>
      <Typography variant="body2" sx={{ color: "#757575" }}>
        {profile.email}
      </Typography>
      {profile.phone && (
        <Typography variant="body2" sx={{ color: "#757575", marginTop: 1 }}>
          Phone: {profile.phone}
        </Typography>
      )}
      <Button
        variant="contained"
        sx={{ marginTop: 2, marginRight: 2 }}
        onClick={() => setIsEditing(true)}
      >
        Edit Profile
      </Button>
      <Button variant="outlined" color="error" sx={{ marginTop: 2 }} onClick={handleLogout}>
        Logout
      </Button>

      {/* Edit Profile Dialog */}
      <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={profile.displayName}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                displayName: e.target.value,
              }))
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="Phone"
            value={profile.phone}
            onChange={(e) =>
              setProfile((prevProfile) => ({
                ...prevProfile,
                phone: e.target.value,
              }))
            }
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ marginBottom: 2 }}
            disabled={isUploading}
          >
            {isUploading ? "Uploading..." : "Upload Profile Picture"}
            <input type="file" hidden onChange={handleImageUpload} />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)} color="secondary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSaveChanges}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
