export const carModels = {
    "Maruti Suzuki Swift": "https://promodeals.app/JHI8fWEqQd/",
    "Maruti Suzuki Alto 800": "https://promodeals.app/ayFnVZA0UH/",
    "Maruti Suzuki Wagon R": "https://promodeals.app/DrJmi3Iy7E/",
    "Maruti Suzuki Baleno": "https://promodeals.app/NHyCh34imV/",
    "Maruti Suzuki Dzire": "https://promodeals.app/vAf0wASSND/",
    "Maruti Suzuki S-Presso": "https://promodeals.app/MvRcmICxZQ/",
    "Maruti Suzuki Ertiga": "https://promodeals.app/vxkCtWcZRr/",
    "Maruti Suzuki Celerio": "https://promodeals.app/8DK1c3JmGI/",
    "Maruti Suzuki Ignis": "https://promodeals.app/9gby53e5AK/",
    "Maruti Suzuki Ciaz": "https://promodeals.app/IBp70VEjoe/",
    "Maruti Suzuki XL6": "https://promodeals.app/ZOVBSugw9k/",
    "Maruti Suzuki Brezza": "https://promodeals.app/fCq5yHXyg1/",
    "Maruti Suzuki Grand Vitara": "https://promodeals.app/55VOYhqPwy/",
    "Hyundai i20": "https://promodeals.app/NJnXSpK6Ew/",
    "Hyundai i10 Nios": "https://promodeals.app/giRlpL3qj2/",
    "Hyundai Venue": "https://promodeals.app/o7xW9CRIvz/",
    "Hyundai Verna": "https://promodeals.app/6uxwb0Lejo/",
    "Hyundai Creta": "https://promodeals.app/izFMEdLcrI/",
    "Hyundai Tucson": "https://promodeals.app/qYsiwyPuJl/",
    "Hyundai Alcazar": "https://promodeals.app/wojWLp2t8z/",
    "Hyundai Aura": "https://promodeals.app/3CbtfRSdBX/",
    "Hyundai Santro": "https://promodeals.app/zzHVR3TaW7/",
    "Hyundai Kona Electric": "https://promodeals.app/jeGn66R4ao/",
    "Hyundai Exter": "https://promodeals.app/bahKqX9qjU/",
    "Tata Nexon": "https://promodeals.app/fagex50n6l/",
    "Tata Tiago": "https://promodeals.app/XxFSB2FGJG/",
    "Tata Altroz": "https://promodeals.app/rXRiukFkGF/",
    "Tata Punch": "https://promodeals.app/k8WstnhMNW/",
    "Tata Tigor": "https://promodeals.app/AW653HzlFL/",
    "Tata Harrier": "https://promodeals.app/S6278V524w/",
    "Tata Safari": "https://promodeals.app/ULqvFbdlXn/",
    "Tata Nexon EV": "https://promodeals.app/5fWo4Ed37q/",
    "Tata Tiago EV": "https://promodeals.app/RkRqITVBDs/",
    "Tata Altroz EV": "https://promodeals.app/g3j0Z7SMUh/",
    "Mahindra Thar": "https://promodeals.app/YABbnXg5UJ/",
    "Mahindra Scorpio N": "https://promodeals.app/tFoe7B4DFb/",
    "Mahindra Bolero": "https://promodeals.app/oQY84NSptM/",
    "Mahindra XUV300": "https://promodeals.app/35JIx6absW/",
    "Mahindra XUV700": "https://promodeals.app/JwgHBUtiJJ/",
    "Mahindra Marazzo": "https://promodeals.app/2tJIQOr2Aj/",
    "Mahindra KUV100": "https://promodeals.app/IdbYDI5t4x/",
    "Mahindra Bolero Neo": "https://promodeals.app/HGmQNZtHnN/",
    "Mahindra eVerito": "https://promodeals.app/8hVgbLQgI2/",
    "Mahindra XUV400 EV": "https://promodeals.app/JF9KSOXnqa/",
    "Toyota Fortuner": "https://promodeals.app/bSl5Gm52EJ/",
    "Toyota Innova Crysta": "https://promodeals.app/sQppClkCRK/",
    "Toyota Glanza": "https://promodeals.app/SIoSAUSR7f/",
    "Toyota Hilux": "https://promodeals.app/E6eBdr3Kka/",
    "Toyota Urban Cruiser Hyryder": "https://promodeals.app/zUeCwTRGsH/",
    "Toyota Vellfire": "https://promodeals.app/mlUd0LSi3Y/",
    "Toyota Camry Hybrid": "https://promodeals.app/MWPtjN24UK/",
    "Toyota Rumion": "https://promodeals.app/azXSEr5moL/",
    "Honda City": "https://promodeals.app/jVCZ7yEeDZ/",
    "Honda Amaze": "https://promodeals.app/1Uhkfq6J7v/",
    "Honda WR-V": "https://promodeals.app/91XOyBMW0N/",
    "Honda Jazz": "https://promodeals.app/Q6mW3SxCW9/",
    "Honda Elevate": "https://promodeals.app/mtNycLuV0J/",
    "Kia Seltos": "https://promodeals.app/5dK4K5UZCB/",
    "Kia Sonet": "https://promodeals.app/EgFClAwrbl/",
    "Kia Carnival": "https://promodeals.app/oAyun3UCWi/",
    "Kia Carens": "https://promodeals.app/ZIPoNN3r8z/",
    "Kia EV6": "https://promodeals.app/y37oZpkPvM/",
    "MG Hector": "https://promodeals.app/McJLANH9uA/",
    "MG Hector Plus": "https://promodeals.app/aPutl4SgSu/",
    "MG Astor": "https://promodeals.app/Fcb4RrNQfM/",
    "MG Gloster": "https://promodeals.app/l6dr6IxD4i/",
    "MG ZS EV": "https://promodeals.app/hWu5BGaORV/",
    "Renault Kwid": "https://promodeals.app/xuK0HUJxPe/",
    "Renault Triber": "https://promodeals.app/VnxVDrsjEx/",
    "Renault Kiger": "https://promodeals.app/mPKtSLSGBN/",
    "Renault Duster": "https://promodeals.app/WWMZSkr5eX/",
    "Volkswagen Polo": "https://promodeals.app/7zj4pkWE34/",
    "Volkswagen Virtus": "https://promodeals.app/E2Lyb59nBK/",
    "Volkswagen Taigun": "https://promodeals.app/9qKHXRhNGQ/",
    "Volkswagen Tiguan": "https://promodeals.app/JvBZyGj3RG/",
    "Skoda Kushaq": "https://promodeals.app/TNv6tT9gta/",
    "Skoda Slavia": "https://promodeals.app/bWvpK1ERNk/",
    "Skoda Octavia": "https://promodeals.app/jN3j1NWLBT/",
    "Skoda Superb": "https://promodeals.app/j7p0Ur6rss/",
    "Skoda Kodiaq": "https://promodeals.app/Vl8wTtGKHn/",
    "Nissan Magnite": "https://promodeals.app/mDj1h4nHYO/",
    "Nissan Kicks": "https://promodeals.app/rj5YuGBUDw/",
    "Jeep Compass": "https://",
    "Jeep Meridian": "https://",
    "Jeep Wrangler": "https://",
    "Jeep Grand Cherokee": "https://",
    "Ford EcoSport": "https://",
    "Ford Endeavour": "https://",
    "Ford Figo": "https://",
    "Ford Freestyle": "https://",
    "Ford Aspire": "https://",
    "Mercedes-Benz A-Class": "https://",
    "Mercedes-Benz GLA": "https://",
    "Mercedes-Benz C-Class": "https://",
    "Mercedes-Benz E-Class": "https://",
    "Mercedes-Benz GLC": "https://",
    "Mercedes-Benz S-Class": "https://",
    "Mercedes-Benz GLE": "https://",
    "Mercedes-Benz GLS": "https://",
    "Mercedes-Benz EQS": "https://",
    "Mercedes-Benz EQC": "https://",
    "BMW 2 Series": "https://",
    "BMW 3 Series": "https://",
    "BMW 5 Series": "https://",
    "BMW 7 Series": "https://",
    "BMW X1": "https://",
    "BMW X3": "https://",
    "BMW X5": "https://",
    "BMW X7": "https://",
    "BMW i4": "https://",
    "BMW iX": "https://",
    "Audi A4": "https://",
    "Audi A6": "https://",
    "Audi Q3": "https://",
    "Audi Q5": "https://",
    "Audi Q7": "https://",
    "Audi Q8": "https://",
    "Audi e-tron": "https://",
    "Jaguar XF": "https://",
    "Jaguar F-Pace": "https://",
    "Jaguar I-Pace": "https://",
    "Land Rover Range Rover Evoque": "https://",
    "Land Rover Discovery Sport": "https://",
    "Land Rover Defender": "https://",
    "Land Rover Range Rover Velar": "https://",
    "Land Rover Range Rover": "https://",
    "Volvo XC40": "https://",
    "Volvo XC60": "https://",
    "Volvo XC90": "https://",
    "Volvo S60": "https://",
    "Volvo S90": "https://",
    "Porsche 718": "https://",
    "Porsche 911": "https://",
    "Porsche Macan": "https://",
    "Porsche Cayenne": "https://",
    "Porsche Panamera": "https://",
    "Porsche Taycan": "https://",
    "Lamborghini Huracan": "https://",
    "Lamborghini Urus": "https://",
    "Lamborghini Aventador": "https://",
    "Ferrari Roma": "https://",
    "Ferrari Portofino": "https://",
    "Ferrari 812 Superfast": "https://",
    "Ferrari SF90 Stradale": "https://",
    "Rolls-Royce Phantom": "https://",
    "Rolls-Royce Ghost": "https://",
    "Rolls-Royce Wraith": "https://",
    "Rolls-Royce Dawn": "https://",
    "Rolls-Royce Cullinan": "https://",
    "Bentley Continental GT": "https://",
    "Bentley Flying Spur": "https://",
    "Bentley Bentayga": "https://",
  };
  
  export const bikeModels = {
    "Hero Splendor Plus": "https://promodeals.app/x9fe4wX8LR/",
    "Hero HF Deluxe": "https://promodeals.app/yvyaG76JhP/",
    "Hero Passion Pro": "https://promodeals.app/hdCXKzL7id/",
    "Hero Glamour": "https://promodeals.app/48CZjGnPHC/",
    "Hero Xtreme 160R": "https://promodeals.app/3fT4J0FQ87/",
    "Hero Super Splendor": "https://promodeals.app/l9ESdY3ukN/",
    "Hero Xpulse 200": "https://promodeals.app/jlI9uWEBF4/",
    "Hero Xpulse 200T": "https://promodeals.app/Xutod24om6/",
    "Hero Maestro Edge 125": "https://promodeals.app/CoBVrN2d4s/",
    "Hero Destini 125": "https://promodeals.app/hXbw1AAmSD/",
    "Honda Shine": "https://promodeals.app/xCYJOR0tQQ/",
    "Honda SP 125": "https://promodeals.app/EKj1fYbvhB/",
    "Honda Unicorn": "https://promodeals.app/IBdCJHRm2V/",
    "Honda CB200X": "https://promodeals.app/NC1ocPxqFH/",
    "Honda CB Hornet 2.0": "https://promodeals.app/ql3pPrNo52/",
    "Honda Livo": "https://promodeals.app/ZSYeRdvGEu/",
    "Honda Hness CB350": "https://promodeals.app/N5nT2I73y0/",
    "Honda X-Blade": "https://promodeals.app/aGkEjSSQfN/",
    "Honda Dio": "https://promodeals.app/P82pq5kL2b/",
    "Honda Activa 6G": "https://promodeals.app/IiyNYZinFv/",
    "Honda Activa 125": "https://promodeals.app/3MfCWVQuIW/",
    "TVS Apache RTR 160": "https://promodeals.app/P42Og46atQ/",
    "TVS Apache RTR 200 4V": "https://promodeals.app/7fomzlf5bS/",
    "TVS Apache RR 310": "https://promodeals.app/iuk7pdAx5E/",
    "TVS Radeon": "https://promodeals.app/ploIW3TWQ3/",
    "TVS Star City Plus": "https://promodeals.app/KF0fTgj6Ft/",
    "TVS Jupiter": "https://promodeals.app/rbzapxmrJ4/",
    "TVS Ntorq 125": "https://promodeals.app/deXgVu76FT/",
    "TVS Sport": "https://promodeals.app/HA58bSgD8A/",
    "TVS XL100": "https://promodeals.app/9IguZgaGXK/",
    "TVS iQube Electric": "https://promodeals.app/oz0JZmF3Yu/",
    "Bajaj Pulsar 125": "https://promodeals.app/hIm5afr11N/",
    "Bajaj Pulsar 150": "https://promodeals.app/ShUJEVe0L7/",
    "Bajaj Pulsar NS160": "https://promodeals.app/C0jXILW9Du/",
    "Bajaj Pulsar NS200": "https://promodeals.app/k78fKjTEmH/",
    "Bajaj Pulsar 220F": "https://promodeals.app/9JPBfesUwl/",
    "Bajaj Dominar 250": "https://promodeals.app/r7KGdB4j9M/",
    "Bajaj Dominar 400": "https://promodeals.app/vdZaqBhCvK/",
    "Bajaj Avenger Street 160": "https://promodeals.app/fv9ij2LiTP/",
    "Bajaj Avenger Cruise 220": "https://promodeals.app/frnAeBCYHH/",
    "Bajaj Platina 100": "https://promodeals.app/SkYlNXIJHm/",
    "Bajaj Platina 110 H Gear": "https://promodeals.app/OdP9vpRfoy/",
    "Royal Enfield Classic 350": "https://promodeals.app/XbRPghEy1y/",
    "Royal Enfield Bullet 350": "https://promodeals.app/OkRzYzIIs6/",
    "Royal Enfield Meteor 350": "https://promodeals.app/9FdFRRwTn8/",
    "Royal Enfield Himalayan": "https://promodeals.app/JnoLIzjr0d/",
    "Royal Enfield Interceptor 650": "https://promodeals.app/hERuCQ7SSS/",
    "Royal Enfield Continental GT 650": "https://promodeals.app/iGwQ0Td6Lh/",
    "Yamaha FZ S FI": "https://promodeals.app/38eL44dlaY/",
    "Yamaha FZ25": "https://promodeals.app/Nmltu6VB17/",
    "Yamaha MT-15 V2": "https://promodeals.app/OHkwC2woaY/",
    "Yamaha YZF R15 V4": "https://promodeals.app/yLm6PpzEym/",
    "Yamaha Fascino 125": "https://promodeals.app/dqV79rmC7c/",
    "Yamaha RayZR 125": "https://promodeals.app/3kScNf4LSu/",
    "Yamaha Aerox 155": "https://promodeals.app/ZuDs8hawRq/",
    "KTM Duke 125": "https://promodeals.app/ZwLxaK4XYg/",
    "KTM Duke 200": "https://promodeals.app/taAqBK5d1N/",
    "KTM Duke 250": "https://promodeals.app/wksJ7JijfX/",
    "KTM Duke 390": "https://promodeals.app/lDwHjXvQHJ/",
    "KTM RC 125": "https://promodeals.app/CtQqxG4LPq/",
    "KTM RC 200": "https://promodeals.app/IX4salN9hB/",
    "KTM RC 390": "https://promodeals.app/JCxWY8b3eK/",
    "KTM 390 Adventure": "https://promodeals.app/rypZZvsJTq/",
    "Suzuki Gixxer": "https://",
    "Suzuki Gixxer SF": "https://",
    "Suzuki Intruder": "https://",
    "Suzuki Access 125": "https://",
    "Suzuki Burgman Street 125": "https://",
    "Mahindra Mojo 300": "https://",
    "Mahindra Gusto 125": "https://",
    "Jawa 42": "https://",
    "Jawa Perak": "https://",
    "Jawa Classic": "https://",
    "Kawasaki Ninja 300": "https://",
    "Kawasaki Ninja 400": "https://",
    "Kawasaki Ninja 650": "https://",
    "Kawasaki Z650": "https://",
    "Kawasaki Z900": "https://",
    "Kawasaki Vulcan S": "https://",
    "Triumph Bonneville T100": "https://",
    "Triumph Street Twin": "https://",
    "Triumph Street Triple RS": "https://",
    "Triumph Trident 660": "https://",
    "Triumph Tiger 900": "https://",
    "Harley-Davidson Iron 883": "https://",
    "Harley-Davidson Forty-Eight": "https://",
    "Harley-Davidson Street Bob": "https://",
    "Harley-Davidson Fat Boy": "https://",
    "Harley-Davidson Street Glide Special": "https://",
    "BMW G 310 R": "https://",
    "BMW G 310 GS": "https://",
    "BMW F 900 R": "https://",
    "BMW R 1250 GS": "https://",
    "BMW S 1000 RR": "https://",
    "Ducati Scrambler Icon": "https://",
    "Ducati Panigale V2": "https://",
    "Ducati Monster": "https://",
    "Ducati Multistrada 950": "https://",
    "Ducati Multistrada V4": "https://",
    "KTM 890 Duke": "https://promodeals.app/rbJFeoe5LZ/",
    "KTM RC 8C": "https://promodeals.app/9O8KCrDXNH/",
    "Benelli 302R": "https://",
    "Benelli TRK 502": "https://",
    "Benelli Leoncino": "https://",
    "CFMOTO 300NK": "https://",
    "CFMOTO 650MT": "https://",
    "CFMOTO 650GT": "https://",
    "CFMOTO 800MT": "https://",
    "Yamaha YZF R1": "https://promodeals.app/KbaQzc465e/",
    "Yamaha YZF R6": "https://promodeals.app/bSIDfLlAhN/",
  };
