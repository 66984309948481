import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import MuiAlert from "@mui/material/Alert";
import { collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { carModels, bikeModels } from "../data";

const storage = getStorage();

const VehicleManager = ({ user }) => {
  const [vehicles, setVehicles] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [vehicle, setVehicle] = useState({
    type: "",
    model: "",
    number: "",
    phone: "",
    mileageImage: null,
    isVerified: false,
  });

  const [modelOptions, setModelOptions] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // success | error | info | warning
  });

  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    vehicleId: null,
  });

  const steps = [
    "Select Vehicle Type",
    "Select Model",
    "Enter Vehicle Number",
    "Upload Mileage Meter Image",
    "Enter Mobile Number",
  ];

  useEffect(() => {
    const fetchVehicles = async () => {
      const querySnapshot = await getDocs(collection(db, "users", user.uid, "vehicles"));
      const vehicleList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVehicles(vehicleList);
    };

    fetchVehicles();
  }, [user]);

  const showSnackbar = (message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const handleTypeSelect = (type) => {
    const typeCount = vehicles.filter((v) => v.type === type).length;
    if (type === "Car" && typeCount >= 5) {
      setErrorMessage("You have reached the limit of 5 cars.");
      return;
    }
    if (type === "Bike" && typeCount >= 5) {
      setErrorMessage("You have reached the limit of 5 bikes.");
      return;
    }

    setErrorMessage("");
    setVehicle({ ...vehicle, type, model: "" });
    setModelOptions(type === "Car" ? Object.keys(carModels) : Object.keys(bikeModels));
    setCurrentStep(1);
  };

  const handleImageUpload = async (e, imageType) => {
    const file = e.target.files[0];
    if (!file) {
      showSnackbar("Please select a file to upload.", "warning");
      return;
    }

    const fileName = `${Date.now()}_${file.name}`;
    const storageRef = ref(storage, `vehicleImages/${user.uid}/${fileName}`);

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      setVehicle((prevVehicle) => ({
        ...prevVehicle,
        [imageType]: downloadURL,
      }));
      showSnackbar("Image uploaded successfully!", "success");
    } catch (error) {
      console.error("Error uploading image:", error);
      showSnackbar("Failed to upload image. Please try again.", "error");
    }
  };

  const handleNext = () => {
    switch (currentStep) {
      case 0:
        if (!vehicle.type) {
          setErrorMessage("Please select a vehicle type.");
          return;
        }
        break;
      case 1:
        if (!vehicle.model) {
          setErrorMessage("Please select a model.");
          return;
        }
        break;
      case 2:
        const vehicleNumberRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/; // Example: TN01AB1234
        if (!vehicle.number || !vehicleNumberRegex.test(vehicle.number.toUpperCase())) {
          setErrorMessage("Please enter a valid vehicle number (e.g., KA01AB1234).");
          return;
        }
        break;
      case 3:
        if (!vehicle.mileageImage) {
          setErrorMessage("Please upload the mileage image.");
          return;
        }
        break;
      default:
        break;
    }

    setErrorMessage("");
    setCurrentStep(currentStep + 1);
  };

  const handleBack = () => {
    setCurrentStep(currentStep - 1);
    setErrorMessage("");
  };

  const handleSave = async () => {
    const vehicleNumberRegex = /^[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{4}$/; // Example: TN01AB1234
    if (
      !vehicle.type ||
      !vehicle.model ||
      !vehicle.number ||
      !vehicle.mileageImage ||
      !vehicle.phone ||
      vehicle.phone.length !== 10 ||
      !/^\d{10}$/.test(vehicle.phone) ||
      !vehicleNumberRegex.test(vehicle.number.toUpperCase())
    ) {
      setErrorMessage("Please ensure all details are valid, including a correct vehicle number.");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "users", user.uid, "vehicles"), vehicle);
      setVehicles([...vehicles, { ...vehicle, id: docRef.id }]);
      showSnackbar("Vehicle added successfully!", "success");
      setVehicle({
        type: "",
        model: "",
        number: "",
        phone: "",
        mileageImage: null,
        isVerified: false,
      });
      setCurrentStep(0);
      setIsDialogOpen(false);
    } catch (error) {
      console.error("Error saving vehicle:", error);
      showSnackbar("Failed to save vehicle.", "error");
    }
  };

  const handleOpenDeleteDialog = (vehicleId) => {
    setDeleteDialog({ open: true, vehicleId });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({ open: false, vehicleId: null });
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "users", user.uid, "vehicles", deleteDialog.vehicleId));
      setVehicles((prevVehicles) =>
        prevVehicles.filter((v) => v.id !== deleteDialog.vehicleId)
      );
      showSnackbar("Vehicle deleted successfully!", "success");
    } catch (error) {
      console.error("Error deleting vehicle:", error);
      showSnackbar("Failed to delete vehicle. Please try again.", "error");
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleRewardClick = (isVerified, model, type) => {
    const rewardLink =
      type === "Car" ? carModels[model] : type === "Bike" ? bikeModels[model] : null;
  
    if (!rewardLink) {
      showSnackbar("Reward link not found for this model.", "error");
      return;
    }
  
    if (!isVerified) {
      showSnackbar(
        "Vehicle is not verified. You can still view rewards, but verification is required to claim them.",
        "warning"
      );
    }
  
    window.open(rewardLink, "_blank", "noopener noreferrer");
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ display: "flex", justifyContent: "space-between" }}>
        My Garage
        <Button variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
          Add Vehicle
        </Button>
      </Typography>

      {vehicles.map((v) => (
        <Card key={v.id} sx={{ display: "flex", alignItems: "center", marginBottom: 2, padding: 2 }}>
          <Avatar src={v.mileageImage} alt={v.model} sx={{ width: 80, height: 80, marginRight: 2 }} />
          <CardContent>
            <Typography variant="h6">
              {v.type} - {v.model}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {v.number}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, marginTop: 1 }}>
              {/* Rewards Button */}
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => handleRewardClick(v.isVerified, v.model, v.type)}
              >
                Rewards
              </Button>

              {/* Delete or Verify Icon */}
              {!v.isVerified ? (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => handleOpenDeleteDialog(v.id)}
                  sx={{ minWidth: "auto", padding: 1 }}
                >
                  <DeleteOutlineIcon />
                </Button>
              ) : (
                <CheckCircleOutlineIcon
                  sx={{
                    color: "green",
                    fontSize: 24,
                    display: "inline-block",
                    marginLeft: 1,
                  }}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      ))}


      {/* Add Vehicle Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Add Vehicle</DialogTitle>
        <DialogContent>
          <Stepper activeStep={currentStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {currentStep === 0 && (
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <Typography variant="h6">Select Vehicle Type</Typography>
              <Box sx={{ display: "flex", justifyContent: "center", gap: 4, marginTop: 3 }}>
                {/* Car Icon Button */}
                <Button
                  variant="contained"
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleTypeSelect("Car")}
                >
                  <DirectionsCarIcon sx={{ fontSize: 50 }} />
                </Button>

                {/* Bike Icon Button */}
                <Button
                  variant="contained"
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => handleTypeSelect("Bike")}
                >
                  <TwoWheelerIcon sx={{ fontSize: 50 }} />
                </Button>
              </Box>
            </Box>
          )}

          {currentStep === 1 && (
            <Box>
              <Typography variant="h6">Select Model</Typography>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Select Model</InputLabel>
                <Select
                  value={vehicle.model}
                  onChange={(e) => setVehicle({ ...vehicle, model: e.target.value })}
                >
                  {modelOptions.map((model) => (
                    <MenuItem key={model} value={model}>
                      {model}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          {currentStep === 2 && (
            <Box>
              <Typography variant="h6">Enter Vehicle Number</Typography>
              <TextField
                fullWidth
                label="Vehicle Number"
                value={vehicle.number}
                onChange={(e) => setVehicle({ ...vehicle, number: e.target.value })}
                sx={{ marginTop: 2 }}
                helperText="Format: TN01AB1234 or similar"
                error={!!errorMessage && currentStep === 2}
              />
            </Box>
          )}

          {currentStep === 3 && (
            <Box>
              <Typography variant="h6">Upload Mileage Meter Image</Typography>
              <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleImageUpload(e, "mileageImage")}
                />
              </Button>
            </Box>
          )}

          {currentStep === steps.length - 1 && (
            <Box>
              <Typography variant="h6">Enter Mobile Number</Typography>
              <TextField
                fullWidth
                label="Mobile Number"
                value={vehicle.phone}
                onChange={(e) => setVehicle({ ...vehicle, phone: e.target.value })}
                sx={{ marginTop: 2 }}
                helperText="Please enter a valid 10-digit mobile number."
              />
            </Box>
          )}

          {errorMessage && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {errorMessage}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBack} disabled={currentStep === 0} color="secondary">
            Back
          </Button>
          {currentStep < steps.length - 1 && (
            <Button onClick={handleNext} color="primary">
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialog.open} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this vehicle? This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
          elevation={6}
          variant="filled"
        >
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default VehicleManager;
