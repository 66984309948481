import React, { useState } from "react";
import Login from "./components/Login";
import Profile from "./components/Profile";
import VehicleManager from "./components/VehicleManager";
import { Box, Grid } from "@mui/material";

const App = () => {
  const [user, setUser] = useState(null);

  return (
    <Box sx={{ backgroundColor: "#f4f6f8", minHeight: "100vh" }}>
      {!user ? (
        <Login onLogin={setUser} />
      ) : (
        <Grid container spacing={2} sx={{ padding: 4 }}>
          <Grid item xs={12} md={4}>
            <Profile user={user} />
          </Grid>
          <Grid item xs={12} md={8}>
            <VehicleManager user={user} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default App;
